import { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Card, Col, Form, FormGroup, Row, InputGroup, Table, Spinner, Nav } from "react-bootstrap";
import { OptionMens } from "./data"
import MultiSelect from "../../../common/MultiSelect";
import { filterProduct } from "../../../../redux/actions/grid";
import apiFc from "../../../../services/APIFc";
import { setPedidoActualRenglones } from "../../../../redux/actions/pedido";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from "react";
import { isMobile } from 'react-device-detect';
import ReactFacebookPixel from 'react-facebook-pixel';
import DatePicker from "react-datepicker";
import { Box } from "@mui/material";
import InputCarritoCompra from "../../../common/InputCarritoCompra";
import { Dropdown } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { decryptData } from "../../../../functions/cryptoUtils";

const Catalogo = () => {
  const dispatch = useDispatch();
  const [Mens, setMens] = useState<any>("");
  const [fetchingData, setFetchingData] = useState(true);
  const [rubros, setRubros] = useState([]);
  const { pedidoActualRenglones } = useSelector((store: any) => store.pedidoreducer);
  const [cantidadArticuloCheck, setCantidadArticuloCheck] = useState<number>(0)
  //Variable para la consulta de articulos
  const [fechaAlta, setFechaAlta] = useState(new Date());

  //productSearch lo utilizo para administrar el input del usuario en la guirlla de busqueda
  const [productSearch, setProductSearch] = useState<any>('');

  const  gridPedidoArticulosBusqueda = useSelector((store: any) => decryptData(store.gridreducer.gridPedidoArticulosBusqueda));

  // aqui en donde localmente pongo el valor de  gridPedidoArticulosBusqueda para dibujar en la tabla
  const [articulos, setArticulos] = useState<any[]>([]);

  const [searchType, setSearchType] = useState({
    value: "1",
    label: "Buscar por codigo/descripcion"
  });

  const filterBy = () => true;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [isLoadingArticulosGrupoSeleccionado, setIsLoadingArticulosGrupoSeleccionado] = useState(false);
  const [articulosGrupoSeleccionado, setArticulosGrupoSeleccionado] = useState<number>();

  useEffect(() => {
    setArticulos(gridPedidoArticulosBusqueda)
  }, [gridPedidoArticulosBusqueda,options]);

  useEffect(() => {
    if (articulosGrupoSeleccionado)
      apiFc.get(`/articuloGrupos/${articulosGrupoSeleccionado}/articulos`).then(res => {
        dispatch(filterProduct(res.data));
        setIsLoadingArticulosGrupoSeleccionado(false)
      })
  }, [articulosGrupoSeleccionado])

  useEffect(() => {
    try {
      apiFc.get(`/articuloRubros`).then(res => {
        setRubros(res.data)
      })
    } catch (error) {
    }
  }, [])

  useEffect(() => {
  }, [rubros, fetchingData])

  useEffect(() => {
    try {
      apiFc.get(`/web/articulos/consulta?filter=${productSearch}`).then(res => {
        
        dispatch(filterProduct(res.data));
        setFetchingData(false)
      })
    } catch (error) {
      setFetchingData(false)
    }
  }, [productSearch])

  const handleInput = (event: any) => {
    setProductSearch(event.target.value);
  };

  function buscarProductosPorLinea(id: any) {
    try {
      apiFc.get(`/web/articulos/rubros/lineas/${id}`).then(res => {
        
        dispatch(filterProduct(res.data));
        setFetchingData(false)
      })
    } catch (error) {
      setFetchingData(false)
    }

  }

  let [showRubros, setShowRubros] = useState(false);
  let [showFilter, setShowFilter] = useState(true);

  function collapseFilter(): void {
    if (showFilter === false) {
      setShowFilter(true);
    } else if (showFilter === true) {
      setShowFilter(false);
    }
  }
  function collapseRubros(): void {
    if (showRubros === false) {
      setShowRubros(true);
    } else if (showRubros === true) {
      setShowRubros(false);
    }
  }



  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  useEffect(() => {
    setAlertDialog({ ...alertDialog, visible: false })
    window.scrollTo(0, 0)
  }, [])

  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }


  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between wd-100p">
        <div className="wd-100p">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Catalogo digital</span>
          {alertDialog.visible && <Mensaje ></Mensaje>}
          <Col  className="mb-md-0 mb-3">
          <Card className="wd-100p pos-relative">
            <Card.Body className="p-2">
            <div className="input-group ">
                  {(searchType.value === "1") ?
                    <div className="input-group wd-70p">
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Buscar ..."
                        onChange={handleInput}//(e: any) => setProductSearch(e.target.value)}
                      />
                      <span className="input-group-append">
                        <Button variant="" className="btn btn-primary" type="button" onClick={handleInput}>
                          Buscar
                        </Button>
                      </span>
                    </div>
                    :
                    <AsyncTypeahead
                      className="wd-70p"
                      filterBy={filterBy}
                      id="productSearch"
                      isLoading={isLoading}
                      placeholder={(searchType.value === "1") ? "Buscar por articulo codigo o descripcion" : "Buscar articulo grupo"}
                      promptText={""}//"Para confirmar busqueda por grupo oprima la tecla espacio"
                      searchText="Buscando..."
                      emptyLabel="Articulo grupo no encontrado"
                      minLength={3}
                      //labelKey="value"
                      onSearch={(query: string) => {
                        if (searchType.value === "2") { //busqueda por articulo grupo 
                          setIsLoading(true);
                          apiFc.get(`/articuloGrupos/page?filter=${query}&page=0&rows=20`)
                            .then(res => {
                              setOptions(res.data.content.map((sugerencia: any) => ({
                                value: sugerencia.articuloGrupoId,
                                label: `${sugerencia.articuloGrupoDescripcion}`,
                                image: `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${sugerencia.articuloGrupoImagenGXI}`,
                              })
                              ));
                              setIsLoading(false);
                            })
                        } else {
                          setIsLoading(false);
                          setOptions([])
                        }
                      }}
                      options={options}
                      onKeyDown={(e: any) => { }}
                      onChange={(e: any) => {
                        if (e.length > 0) {
                          setIsLoadingArticulosGrupoSeleccionado(true)
                          setArticulosGrupoSeleccionado(e[0].value)
                        }
                        setIsLoading(false);
                      }}
                      onFocus={(e: any) => {
                        e.target.select()
                      }
                      }
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <img className="pic-2" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${option.image}`} alt="product"
                            onError={(e: any) => {
                              e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                            }}
                            style={{
                              height: '36px',
                              marginRight: '10px',
                              width: '36px',
                            }}
                          />
                          <span>{option.label}</span>
                        </>
                      )}
                    />
                  }
                  <span className="pd-l-15 wd-30p">
                    <Select defaultValue={searchType}
                      options={[
                        {
                          value: "1",
                          label: "Buscar por codigo/descripcion"
                        },
                        {
                          value: "2",
                          label: "Buscar por grupo"
                        }
                      ]}
                      onChange={(data: any) => {
                        setSearchType(data)
                      }}
                    />
                  </span>
                </div>
            </Card.Body>
          </Card>
        </Col>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <!-- row --> */}
      <Row className="row-sm">
        
        <Col >
          <Row className="wd-100p">
            <Card className="wd-100p">
              <div className="wd-100p">
                <Nav
                  className="nav nav-pills nav-pills-circle wd-100p justify-content-center"
                  //defaultActiveKey="link-1"
                  id="tabs_2"
                  role="tablist"
              
                >
                  {rubros.map((rubro: any) => {
                    return (<Nav.Item key={`${rubro.id}`} className="nav-item-catalogo dropdown wd-15p align-items-center  mg-r-10 ">
                      <Nav.Link
                        eventKey={`${rubro.id}`}
                        className="nav-link border py-2 px-3 m-2 wd-100p "
                        id={`${rubro.id}`}
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="nav-link-icon d-flex text-center justify-content-center ">
                          <i className="fe fe-home  "></i> {rubro.detalle}
                        </span>
                        <div className="dropdown-menu mg-l-7" aria-labelledby="tab1">
                          {rubro?.articuloRubroLineaList.map((linea: any) => (
                            <a className="dropdown-item" href={linea.id} onClick={() => {
                              collapseRubros()
                              setFetchingData(true)
                              buscarProductosPorLinea(linea.id)
                            }}>{linea.detalle}</a>))
                          }
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    )
                  })}
                </Nav>
              </div>
            </Card>
          </Row>
          <Row className="row-sm">
            {fetchingData  || isLoadingArticulosGrupoSeleccionado ?
              <Spinner
                animation="border"
                variant="primary"
                className="spinner-border me-2 text-primary"
                role="status"
              ></Spinner>
              :
              articulos.length !== 0 &&
              articulos.map((articulo: any, id: any) => {
              
                return (
                  <Col sm={6} xl={4} xxl={3} lg={6} md={6} key={articulo.articuloCodigo} id={articulo.articuloCodigo}>
                    <Card>
                      <Card.Body className="h-100  product-grid6">
                        <div className="pro-img-box product-image" >
                          <div onClick={() => {
                          }}>
                            <img className=" pic-1" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenGXI}`} alt="product"
                              onError={(e: any) => {
                                e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                              }} />
                          </div>
                          <ul className={"icons"}>
                            <li>
                              <div className="btn btn-primary"  >
                                <i className="fa fa-heart"></i>
                              </div>
                            </li>
                          </ul>
                          {/* </Link> */}
                          {/* <ul className="icons">
                            <li>{/* <li >onClick={() => send(item)}> */}
                          {/* <Link to={`${process.env.PUBLIC_URL}/pages/e-commerce/shop/`}> *//*}
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Agregar a deseo</Tooltip>}
                              >
                                <div className="primary-gradient me-2"  >

                                  <i className="fa fa-heart"></i>

                                </div>
                              </OverlayTrigger>
                              {/* </Link> *//*}
                            </li>
                            <li >
                              {/* <li onClick={() => send(item)}> */}

                          {/* <Link to={`${process.env.PUBLIC_URL}/pages/e-commerce/shop/`}> *//*}
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Agregar al pedido</Tooltip>}
                              >
                                <div className="secondary-gradient me-2" >

                                  <i className="fa fa-shopping-cart"></i>

                                </div>
                              </OverlayTrigger>
                              {/* </Link> *//*}
                            </li>

                            <li>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Vista Rapida</Tooltip>}
                              >
                                <div className="info-gradient">
                                  <i className="fas fa-eye"></i>
                                </div>
                              </OverlayTrigger>
                            </li>
                          </ul> */}
                        </div>
                        <div className="text-center pt-2">
                          <h4 className="h6 mb-2 mt-4 font-weight-bold text-uppercase">
                            {articulo.articuloCodigo}
                          </h4>
                          <h3 className="h6 ht-50 mb-2 mt-4 font-weight-bold text-uppercase">
                            {articulo.articuloDescripcion}
                          </h3>
                          <h4 className="h5 mb-0 mt-1 text-center font-weight-bold  tx-22">
                            {articulo.articuloUnidadVenta}{':       $'}{articulo.articuloPrecio}{" "}
                          </h4>
                          <InputCarritoCompra
                            articulo={articulo}
                            origen="catalogo"
                            onEvent={(e: any) => setAlertDialog(e)}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })

            }
          </Row>
        </Col>
      </Row >

      {/* <!-- row closed --> */}
    </div >
  );
}



export default Catalogo