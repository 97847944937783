import React, { useEffect, useReducer, useState } from "react";
import { Breadcrumb, Button, Card, Col, NavItem, Row, Tab, Table, Tabs } from "react-bootstrap";

import apiFc from "../../../services/APIFc";
import { filterComprobante } from "../../../redux/actions/resumen";
import { resumenreducer } from "../../../redux/reducers/resumen";
import { decryptData } from "../../../functions/cryptoUtils";
import Descarga from "../../Descarga/Descarga";


const MisComprobantes = () => {
  //para disparar las llamadas a la api
  //const dispatch = useDispatch();

  //aplico el useselector sobre este valor por que ahi es donde el Axios+Redux almacenen el resultado de la busqueda
  //Esta es otra forma de usar selector, mejor o peor???
  const [resumenReducer, dispatch] = useReducer(resumenreducer, []);
  //const { resumenReducer } = useSelector((store: any) => store.resumenreducer);

  //Administrador de las tabs
  const [tabKey, setTabKey] = useState('tCtaCte');
  //Variable donde se almacenan los comprobantes
  const [data, setData] = useState<any[]>([]);




  useEffect(() => {
    try {
      setData([])
      let sistema = 0;
      let ncPago = 0;
      switch (tabKey) {
        case 'tCtaCte':
          break;
        case 'tPedidos':
          sistema = 1
          break;
        case 'tNcPag':
          ncPago = 1;
          break;
      }
      apiFc.get(`/web/miscomprobantes?sistema=${sistema}&ncPago=${ncPago}`).then(res => {
        dispatch(filterComprobante(res.data));
      })
    } catch (error) {
    }
  }, [tabKey])


  useEffect(() => {
    if (resumenReducer.comprobantes && resumenReducer.comprobantes.length) {
      setData(decryptData(resumenReducer.comprobantes))
    }
  }, [resumenReducer]);

  //a efectos plracticos de refrescar a pantalla
  useEffect(() => {
  }, [data])


  const md5 = require('md5');

  function fechaVencimiento(fechaComprobante: string): any {
    let diasvencidos = Math.floor((Date.now() - Date.parse(fechaComprobante)) / 86400000) - 30
    return (diasvencidos >= 0 ? diasvencidos : "")
  };


  function renderCuerpoTabla(): any {
    if (data.length && data.length !== 0) {
      
      var acumulado = 0;
      return (
        data
          .sort((a: any, b: any) => {
            if (tabKey === 'tNcPag') return b.comprobanteNumero - a.comprobanteNumero;
            else return a.comprobanteNumero - b.comprobanteNumero;
          })
          .map((list: any, index: any) => {
            acumulado += list.comprobanteSaldo;
            const archivoLink = `https://app.ferreteracentral.com.ar/api/reportes/comprobante/v3?clienteCodigo=${list.clienteCodigo}&numeroMovVenta=${list.comprobanteNumero}&sistema=${list.comprobanteSistema}`;
            return (
              <tr key={index}>
                <th scope="row">{list.comprobanteFecha}</th>
                <td>{list.comprobanteDetalle}</td>
                <td>{list.comprobanteImporte}</td>
                <td>{list.comprobanteSaldo}</td>
                <td className="text-center font-weight-bold">{acumulado.toFixed(2)}</td>
                <td className="text-center">{fechaVencimiento(list.comprobanteFecha)}</td>
                <td>
                  {/* saque el boton de descarga y lo reemplazo por el componente el cual trae su boton para manejar la descarga y oculto lo que no necesito */}
                  <Descarga
                    archivoLink={archivoLink}
                    // archivoIcono="file-icon.png" 
                    archivoDetalle={list.comprobanteDetalle}
                    // archivoFecha={list.comprobanteFecha}
                    clienteCodigo={list.comprobanteSistema}
                    md5Hash={md5(`cV0puOlx${list.comprobanteSistema}${list.comprobanteNumero}`)}
                    showArchivoDetalle={false} // oculto el detalle pero lo uso igual para que se descargue con el nombre del archivo
                  />
                </td>
              </tr>
            );
          })
      );
    } else {
      return (
        <div style={{ height: '500px', alignItems: 'center', position: 'relative' }}>
          <span style={{ position: 'absolute', bottom: '50%', left: '50%', whiteSpace: 'nowrap' }}>
            No hay resultados
          </span>
        </div>
      );
    }
  }


  const handleDownload = (e: any) => {
  };



  return (
    <div>

      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Mis comprobantes
          </span>
        </div>

      </div>
      {/* <!-- /breadcrumb --> */}
      <div className="panel panel-primary tabs-style-2">
        <div className=" tab-menu-heading">
          <div className="tabs-menu1">
            <Card>
              <Card.Body>
                {/* <!-- Tabs --> */}
                <Tabs
                  defaultActiveKey="tCtaCte"
                  className=" panel-tabs main-nav-line "
                  onSelect={(k: any) => setTabKey(k)}
                >
                  <Tab eventKey="tCtaCte" title="Cuenta corriente">
                  </Tab>
                  <Tab eventKey="tPedidos" title="Pedidos">
                  </Tab>
                  <Tab eventKey="tNcPag" title="Nota cred. pago">
                  </Tab>
                </Tabs>
                <Card.Body>
                  <div className="table-responsive">

                    <Table className="table mg-b-0 text-md-wrap">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Comprobante</th>
                          <th>Importe</th>
                          <th>Saldo</th>
                          <th>Acumulado</th>
                          <th>Dias Venc.</th>
                          <th>Descarga</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderCuerpoTabla()}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MisComprobantes;
