import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Col, Form, Row, Button, FormGroup, Carousel } from 'react-bootstrap';
import LazyImage from '../../../functions/LazyImage';
import apiFc from '../../../services/APIFc';
import { useSelector } from 'react-redux';
import CardGrupo from '../../Card Grupos/CardGrupo';
import ExtraLargeModal from '../../common/ExtraLargeModal';
import { AlertDialogInterface, SelectOption } from '../../common/Interfaces';
import Select from "react-select";
import { decryptData } from '../../../functions/cryptoUtils';
import { tamañoImagenNuevo } from '../../../functions/Procedimientos';
import ProductosDestacadosRubro from '../../common/ProductosDestacadosRubro';
import ShowMarcasInicio from '../../common/ShowMarcasInicio';
import ShowRubroInicio from '../../common/ShowRubroInicio';
import ProductosMasCompradosInicio from '../../common/ProductosMasCompradosInicio';
import FcCarousel from '../../FcCarousel/FcCarousel';
import MarcaLogo from '../../MarcaLogo/MarcaLogo';

const colorMap: { [key: number]: string } = {
  1: '#D4131E',
  2: '#FF9100',
  3: '#FC4783',
  4: '#59087A',
  5: '#005DD1',
  6: '#009B0B',
};

interface Rubro {
  id: number;
  detalle: string;
}

interface Props {
  rubros: Rubro[];
  handleNavigation: (id: number) => void;
}

const CatalogoPrincial = () => {
  const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);
  const [queryParameters] = useSearchParams();
  const [marcaId, setMarcaId] = useState(queryParameters.get("marcaId"));
  const [articulosGrupo, setArticulosGrupo] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [items, setItems] = useState([]);
  const [filtroLocal, setFiltroLocal] = useState('');
  const [showDetalle, setShowDetalle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filtro, setFiltro] = useState(queryParameters.get("filtro"));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  const marcas = useSelector((store: any) => decryptData(store.principalreducer.marcas) || []);
  const [imageSrc, setImageSrc] = useState<any>();
  const navigate = useNavigate();
  const [ofertas, setOfertas] = useState([]);
  const handleNavigation = (rubroId: any) => {
    navigate(`/principal/catalogorubro?rubroId=${rubroId}`);
  };
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success',
    timeOut: 2000,
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  const imagenes = [
    `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Oferta/section_catalogo/oferta1${tamañoImagenNuevo(window.innerWidth)}.jpg`,
    `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel2${tamañoImagenNuevo(window.innerWidth)}.jpg`,
    `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3${tamañoImagenNuevo(window.innerWidth)}.jpg`,
    `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel4${tamañoImagenNuevo(window.innerWidth)}.jpg`,
  ];

  const colorMap = {
    1: '#D4131E',
    2: '#FF9100',
    3: '#FC4783',
    4: '#59087A',
    5: '#005DD1',
    6: '#009B0B',
  };
  const [visible, setVisible] = useState(Array(imagenes.length).fill(true));

  const handleError = (index: any) => {
    setVisible((prevVisible) => {
      const newVisible = [...prevVisible];
      newVisible[index] = false;
      return newVisible;
    });
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (isMobile) {
      setImageSrc(`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarca2/${marcaId}_${marcaId}.jpg`);
    } else {
      setImageSrc(`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarca2/${marcaId}.jpg`);
    }
  }, [isMobile, marcaId]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setMarcaId(queryParameters.get("marcaId"));
    fetchData();
  }, [queryParameters]);

  useEffect(() => {
    fetchData();
  }, [marcaId]);

  useEffect(() => {
    obtenerOfertas();
  }, [marcaId]);

  useEffect(() => {
    if (selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setArticulosGrupo(res.data);
          setShowDetalle(true);
        });
      } catch (error) { }
    }
  }, [selectedGroup]);

  const fetchData = () => {
    if (marcaId !== null) {
      apiFc.get(`/web/articulos/marcas/${marcaId}/articulogrupos?page=0&rows=1000`)
        .then(res => {
          setItems(res.data.content);
          setFiltroLocal('');
          window.scrollTo(0, 0);
        });
    }
  };

  function obtenerOfertas() {
    apiFc.get(`/web/articulo/oferta`).then(res => {
      setOfertas(res.data);
    }).catch(err => {
      setOfertas([]);
      setAlertDialog({ ...alertDialog, visible: true, cabecera: "Problemas cargando ofertas", texto: `Error 405`, severity: "success" })
    });
  }

  const handleOpenModal = (articulo: any) => {
    setSelectedGroup(articulo);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData();
  };

  let marcasOpciones = (): SelectOption[] => {
    let marcasList: SelectOption[] = [];
    marcas.map((marcam: any) => {
      let opcion: SelectOption = { value: '', label: '' }
      opcion.value = marcam.id
      opcion.label = marcam.descripcion
      marcasList.push(opcion)
    });
    return marcasList;
  };


  const obtenerArticulosOferta = (item: any) => {
    navigate(`${process.env.PUBLIC_URL}/principal/articulos/ofertas/${item.id}`);
  };



  return (
    <div>
      <ExtraLargeModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedGroup={selectedGroup}
        alertDialog={alertDialog}
        // setAlertDialog={setAlertDialog}
        windowWidth={window.innerWidth}
      />
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Catalogo
          </span>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <Row>
        <Col >
          <div className="justify-content-center empezamosPorAca">
            <div className="Withcontrols">
              <Carousel>
                <Carousel.Item>
                  <a
                    href="https://whatsapp.com/channel/0029VagMNruI1rchr3HAYa3z"
                    className="pd-l-40 pd-r-40"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="d-flex wd-100p justify-content-center align-items-center ht-300 seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel1${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        // e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel1_rot${tamañoImagen(window.innerWidth)}.png`
                      }}
                    />
                  </a>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center ht-300 seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel2${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        // e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel2_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center ht-300 seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        //e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center ht-300 seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel4${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        //e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Col >
      </Row > */}
      <Row>
        {rubros.map((rubro: any) => (
          <Col key={rubro.id} xs={12} md={4} className="rubro-col">
            <div className="rubro-item" onClick={() => handleNavigation(rubro.id)}>
              <div className="rubro-image-container">
                <img
                  src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Rubros/PortadaRubros/${rubro.id}_${rubro.id}_${rubro.id}.jpg`}
                  alt={rubro.detalle}
                  className="rubro-image"
                />
              </div>
              <div
                className="color-overlay"
                style={{
                  backgroundColor: colorMap[rubro.id as keyof typeof colorMap],
                }}
              >
                <span className="rubro-text">{rubro.detalle}</span>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <ProductosDestacadosRubro />

      <div className='mg-b-10 mg-t-30'>
        <h1 className=" display-6 tx-20 tx-bold"><strong>Nuestras ofertas</strong></h1>
      </div>
      <Row>
        {ofertas && ofertas.length > 0 &&
          ofertas.slice(0, 4).map((oferta: any, index: number) => (
            <Col key={index} xs={12} md={12} lg={6}>
              <Card.Body className="ht-100p">
                <div className="contenedor-imagen">
                    <img
                      className="imagen br-5"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Oferta/${oferta.id}_${oferta.id}.png`}
                      alt={`Imagen ${index + 1}`}
                      onClick={() => obtenerArticulosOferta(oferta)}
                      onError={(e: any) => {
                        const originalSrc = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${oferta.imagenUrl}`;
                        const fallbackSrc = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                        e.target.src = originalSrc;
                        e.target.onerror = () => e.target.src = fallbackSrc;
                      }}
                    />
                </div>
              </Card.Body>
            </Col>
          ))
        }
      </Row>

      <Row className='mg-t-20 mg-b-30'>
        {(marcas && marcas.length) ? (
          <FcCarousel items={
            marcas.sort(() => 0.5 - Math.random()).slice(0, 8).map((marca: any) => (
              <Col xs={6} sm={6} xl={3} xxl={3} lg={4} md={4} className="mg-t-10" key={marca.id}>
                <MarcaLogo marca={marca} />
              </Col>
            ))
          } />
        ) : (
          <></>
        )}
      </Row>
      <Button className='d-flex m-auto mg-t-10 font-weight-bolder ' onClick={() => navigate(`/principal/marcalistado`)}>Conoce nuestras marcas</Button>
      {/* <ShowRubroInicio /> */}
      <ProductosMasCompradosInicio />
    </div>
  );
};

export default CatalogoPrincial;