import { Button, Carousel, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "./cardgrupo.css";
import { useDispatch, useSelector } from "react-redux";
import { consultaArticuloHeader } from "../../redux/actions/grid";
import InputCarritoCompra from "../common/InputCarritoCompra";
import { useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import LazyImage from "../../functions/LazyImage";

interface shopCardProps {
  onClick?: () => void; // Define the onClick prop as a function
  articuloGrupo: any;
}
ShopCard.defaultProps = {
  name: "Default Name"
};

export default function ShopCard(props: shopCardProps) {
  const grupo = props.articuloGrupo;
  // const [imageError, setImageError] = useState(false); // Estado para rastrear errores de imagen

  function articuloId(): string {
    return grupo?.articuloGrupoId;
  }

  function articuloImagen(): string {
    return grupo?.articuloGrupoImagenGXI || grupo?.articuloGrupoId?.articuloGrupoImagenGXI || "";
  }

  function articuloDescripcion(): string {
    return grupo?.articuloGrupoDescripcion;
  }

  // if (imageError) {
  //   return null; // No renderizar la tarjeta si hay un error de imagen
  // }

  return (
    <div className="card ht-300" id={grupo?.articuloCodigo} onClick={props.onClick}>
      <div className="cardcontainer">
        <div className="picture-container">
          <LazyImage
            className="picture"
            key={articuloId()}
            src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articuloImagen()}`}
            alt="Lazy-loaded Image"
            onError={(e: any) => {
              e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
              // setImageError(true); // Marcar error de imagen
            }}
          />
        </div>
        <div className="cardbody cardcontent">
          <div className="category">
            <span><strong>Grupo</strong></span>
          </div>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{articuloDescripcion()}</Tooltip>}
          >
            <div className="title mg-b-10">
              <h6>{articuloDescripcion()}</h6>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
