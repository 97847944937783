import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Pagination, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setNovedades } from '../../../redux/actions/principal';
import apiFc from '../../../services/APIFc';
import Lightbox from "react-image-lightbox";
import ReactFacebookPixel from 'react-facebook-pixel';
import { decryptData } from '../../../functions/cryptoUtils';

const Novedades = () => {
    const dispatch = useDispatch()
    //const {  } = useSelector((store: any) => store.principalreducer);
    const novedades = useSelector((store: any) => decryptData(store.principalreducer.novedades));

    const [isOpen, setIsOpen] = useState(false);
    const [srcImage, setSrcImage] = useState("");

    useEffect(() => {
        ReactFacebookPixel.trackCustom("viewNovedades", `{"seccion": "novedades"}`);
        apiFc.get(`/web/principal/novedades/all`)
            .then(res => {
                dispatch(setNovedades(res.data))
            }).catch(err => {
                dispatch(setNovedades(null))
            });
    }, [])

    useEffect(() => {

    }, [isOpen])


    return (<div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
            <div className="left-content">
                <span className="main-content-title mg-b-0 mg-b-lg-1">Novedades</span>
                {isOpen && (
                    <Lightbox
                        mainSrc={srcImage}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
            </div>
        </div>
        {/* <!-- /breadcrumb --> */}
        {/* <!-- row --> */}
        <Row>
            {(novedades && novedades.length > 0) ?
                novedades.map((novedad: any, id: any) => (
                    <Col xxl={3} xl={3} lg={3} md={3} key={novedad.novedadId} className="mg-b-10">
                        <Card className="card custom-card card-img-top-1 ht-100p">
                            <img
                                className="card-img-top w-100 w-100"
                                src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${novedad.novedadImagenGXI}`}
                                onClick={() => {
                                    // setSrcImage(`../../../../../img/${novedad.novedadImagenGXI}`)
                                    // setIsOpen(true)
                                }}
                                alt="imagenNovedad"
                                onError={(e: any) => {
                                    e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                }}
                            />
                            <Card.Body>
                                <div className="mt-4">
                                    <h5 className="font-weight-semibold">
                                        {novedad.novedadDetalle}
                                    </h5>
                                </div>
                                <p className="mb-0 mt-3">
                                    {novedad.novedadDetalleLargo}
                                </p>
                            </Card.Body>
                            <Card.Footer>
                                <div className="item7-card-desc d-sm-flex mt-0">
                                    <div className="d-flex">
                                        <span className="fe fe-user text-muted me-2 tx-17"></span>
                                        <div className="mt-0 mt-0  font-weight-semibold text-muted">
                                            Productos
                                        </div>
                                    </div>
                                    <div className="d-sm-flex ms-sm-auto">
                                        <div className="d-flex me-3">
                                            <span className="fe fe-calendar text-muted me-2 tx-17"></span>
                                            <div className="mt-0 mt-0  font-weight-semibold text-muted">
                                                {novedad.novedadFecha}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))
                :
                <Card.Body>
                    <Spinner
                        animation="border"
                        className="spinner-border"
                        role="status"
                    >
                        <span className="sr-only">Cargando...</span>
                    </Spinner>
                </Card.Body>
            }
        </Row>
        {/* <!-- /row --> */}
    </div>);
}

Novedades.propTypes = {};

Novedades.defaultProps = {};

export default Novedades;
