import React from 'react'
import "./cardmarca.css"
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../functions/LazyImage';

const CardMarca = (props: any) => {
    const navigate = useNavigate();

    return (
        <div className="card-marca-container" onClick={() => navigate((window.location.href.includes("/principal")) ? `/principal/catalogomarca?marcaId=${props.marca.id}` : `/catalogomarca?marcaId=${props.marca.id}`)}>
            <div className="card-marca-img-container">
                <LazyImage
                    className=""
                    key={props.marca.id}
                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${props.marca.id}.png`}
                    alt="Imagen 1"
                    onError={(e: any) => {
                        e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                    }} />
                {/* <img
                    src={`../../../../img/Imagenes/Marcas/PortadaMarcas/${props.marca.id}.png`}
                    // src={`../../../../img/Imagenes/Grupos/fotoprueba2.png`}
                    alt=""
                    onError={(e: any) => {
                        e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                    }} /> */}
            </div>
            <div className="logo-overlay">
                <LazyImage
                    className=""
                    key={`over${props.marca.id}`}
                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/${props.marca.id}.jpg`}
                    alt="Imagen 1"
                    onError={(e: any) => {
                        e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                    }} />
                {/* <img
                    src={`../../../../img/Imagenes/Marcas/${props.marca.id}.jpg`}
                    alt=""
                    onError={(e: any) => {
                        e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                    }}
                /> */}
            </div>
            <div className="marca-text-container">
                <div className="card-marca-title">
                    {props.marca.descripcion}
                </div>
            </div>
            <div className="products-container">
                <div className="shop-products">
                </div>
                <div className="products">
                    {
                        props.marca?.articuloListSample?.map((articulo: any, i: any) => (
                            <a
                                key={articulo.articuloCodigo}
                                className="products-item">
                                <LazyImage
                                    className=""
                                    key={`over${articulo.articuloCodigo}`}
                                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenV2}`}
                                    alt="Imagen 1"
                                    onError={(e: any) => {
                                        e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                    }} />
                                {/* <img
                                    src={`../../img/${articulo.articuloImagenV2}`}
                                    alt=""
                                    onError={(e: any) => {
                                        e.target.src = `../../img/Imagenes/Grupos/noimg.png`
                                    }} /> */}
                            </a>)
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CardMarca