import React, { useState } from 'react';
import { Button, Card, Col, Spinner } from 'react-bootstrap';
import apiFc from '../../services/APIFc';
import { useSelector } from 'react-redux';
import { decryptData } from '../../functions/cryptoUtils';

interface DescargaProps {
  archivoLink?: string;
  archivoIcono?: string;
  archivoDetalle?: string;
  archivoFecha?: string;
  clienteCodigo: string;
  md5Hash: string;
  showArchivoDetalle?: boolean;
  mostrarBotonDescarga?: boolean;
}

const Descarga: React.FC<DescargaProps> = ({
  archivoLink,
  archivoIcono,
  archivoDetalle,
  archivoFecha,
  showArchivoDetalle = true, // Valor por defecto es true
  mostrarBotonDescarga = true, // Por defecto el botón se mostrará
  clienteCodigo,
  md5Hash,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const user = useSelector((store: any) => decryptData(store.authreducer.user));

  const md5 = require('md5');
  const descargarArchivo = async () => {
    setIsDownloading(true);
  
    if (archivoLink?.includes(".php")) {
      //  cuando el archivo viene  PHP
      try {
        const link = archivoLink
        ?.replace('CC', user.clienteCodigo)
        .replace('HH', md5(`cV0puOlx${user.clienteCodigo}${new Date().getDate().toString().padStart(2, '0')}`))
        .replace('FF', new Date().toISOString().slice(0, 10));

        const response = await apiFc.get(link, { responseType: 'blob' });

  
        // proceso el archivo
        const contentType = response.headers['content-type'];
        let fileName = `${archivoDetalle}`;
        
        let fileBlob: Blob;

        switch (contentType) {
          case 'application/pdf':
            fileBlob = new Blob([response.data], { type: 'application/pdf' });
            fileName = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`;
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            fileBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`;
            break;
          case 'application/vnd.ms-excel':
            fileBlob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
            fileName = fileName.endsWith('.xls') ? fileName : `${fileName}.xls`;
            break;
          default:
            fileBlob = new Blob([response.data], { type: 'application/octet-stream' });
            fileName = fileName.endsWith('.bin') ? fileName : `${fileName}.bin`;
            break;
        }
  
        // enlace temporal para descargar el archivo
        const linkElement = document.createElement('a');
        const url = window.URL.createObjectURL(fileBlob);
        linkElement.href = url;
        linkElement.setAttribute('download', fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        linkElement?.parentNode?.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error al descargar el archivo PHP:', error);
      } finally {
        setIsDownloading(false);
      }
    } else {
      //descarga de los nuevos archivos
      try {
        const link = archivoLink?.replace(`https://app.ferreteracentral.com.ar/api`, "") + "";

        const response = await apiFc.get(link, { responseType: 'blob' });
  
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];
        let fileName = `${archivoDetalle}`;
  
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch) {
            fileName = fileNameMatch[1];
          }
        }
  
        let fileBlob: Blob;
  
        // aca coloco los tipos de archivos que pueden venir 
        switch (contentType) {
          case 'application/pdf':
            fileBlob = new Blob([response.data], { type: 'application/pdf' });
            fileName = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`;
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            fileBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`;
            break;
          case 'application/vnd.ms-excel':
            fileBlob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
            fileName = fileName.endsWith('.xls') ? fileName : `${fileName}.xls`;
            break;
          default:
            fileBlob = new Blob([response.data], { type: 'application/octet-stream' });
            fileName = fileName.endsWith('.bin') ? fileName : `${fileName}.bin`;
            break;
        }
  
        const linkElement = document.createElement('a');
        const url = window.URL.createObjectURL(fileBlob);
        linkElement.href = url;
        linkElement.setAttribute('download', fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        linkElement?.parentNode?.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      } finally {
        setIsDownloading(false);
      }
    }
  };


  
  const imageSrc = archivoIcono ? 
  require(`../../assets/img/files/${archivoIcono}`) : 
  require('https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png'); 

  return (
    <>
      {!mostrarBotonDescarga && (
        <Col sm={6} xl={2} md={4}>
          <Card className="p-0">
            <Card.Body className="pt-0 text-center">
              {isDownloading ? (
                <div className="text-center mg-b-20">
                  <Spinner
                    animation="border"
                    className="spinner-border"
                    role="status"
                  >
                    <span className="sr-only">Cargando...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  <img
                    src={imageSrc}
                    alt=""
                    className="br-7"
                    onClick={(e) => {
                      e.preventDefault();
                      descargarArchivo();
                    }}
                    style={{ cursor: 'pointer' }} // Para mostrar que es clickeable
                  />
                </div>
              )}
              {showArchivoDetalle && (
                <>
                  <h6 className="mb-1 h-40 font-weight-semibold d-bold">
                    {archivoDetalle}
                  </h6>
                  <span className="text-muted">{archivoFecha}</span>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      )}
      {mostrarBotonDescarga && (
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            descargarArchivo();
          }}
          className="mt-2"
        >
          Descargar
        </Button>
      )}
    </>
  );
};

export default Descarga;


// return (
//   <Col sm={6} xl={2} md={4}>
//     <Card className="p-0">
//       <Card.Body className="pt-0 text-center">
//         <div className="file-manger-icon">
//           <a href="#" onClick={(e) => {
//             e.preventDefault();
//             descargarArchivo();
//           }}>
//             {isDownloading ? (
//               <div className="text-center mg-b-20">
//                 <Spinner
//                   animation="border"
//                   className="spinner-border"
//                   role="status"
//                 >
//                   <span className="sr-only">Cargando...</span>
//                 </Spinner>
//               </div>
//             ) : (
//               <img
//                 src={require(`../../assets/img/files/${archivoIcono}`)}
//                 alt=""
//                 className="br-7"
//               />
//             )}
//           </a>
//         </div>
//         <h6 className="mb-1 h-40 font-weight-semibold">{archivoDetalle}</h6>
//         <span className="text-muted">{archivoFecha}</span>
//       </Card.Body>
//     </Card>
//   </Col>
// );