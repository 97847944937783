import React, { useMemo, useRef } from "react";
import { useEffect, useReducer, useState } from "react";
import { Breadcrumb, Button, Card, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import apiFc from "../../../services/APIFc";
import { StockLevel, formatearPrecio } from "../../../functions/Procedimientos";
import { isMobile } from 'react-device-detect';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import DetalleArticulo from "../../common/DetalleArticulo";
import CartelInformativo from "../../common/CartelInformativo";
import InputCarritoCompra from "../../common/InputCarritoCompra";
import { AlertDialogInterface } from "../../common/Interfaces";
import Select from "react-select";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Joyride from "react-joyride"
import { pasosPDV } from "../../common/TutorialPasos";
import { decryptData } from "../../../functions/cryptoUtils";
import { BarraBusquedaSugerida, respuestaBusqueda } from "../../BarraBusqueda/BarraBusquedaSugerida";
import { filterProduct } from "../../../redux/actions/grid";
import { ComposableMap } from "react-simple-maps";


const PuestoDeVenta = () => {
  const [showDetalleArticulo, setShowDetalleArticulo] = useState<{ visible: boolean, codArticulo: string, fullScreen: string }>();
  const [showTutorial, setShowTutorial] = useState(false);
  //usuario para poder dibujar la data
  const user = useSelector((store: any) => decryptData(store.authreducer.user));

  //Aca admnistramos la busqueda por grupo
  const [isLoadingArticulosGrupoSeleccionado, setIsLoadingArticulosGrupoSeleccionado] = useState(false);
  const [articulosGrupoSeleccionado, setArticulosGrupoSeleccionado] = useState<number>();

  useEffect(() => {
    if (articulosGrupoSeleccionado)
      apiFc.get(`/articuloGrupos/${articulosGrupoSeleccionado}/articulos`)
        .then(res => {
          setListaArticulo(res.data);
          setIsLoadingArticulosGrupoSeleccionado(false)
        })
  }, [articulosGrupoSeleccionado])

  // aqui en donde localmente pongo el valor de  gridPedidoArticulosBusqueda para dibujar en la tabla
  const [listaArticulo, setListaArticulo] = useState<any[]>();

  function buscarArticulos(filtro: string) {
    apiFc.get(`/web/articulos/consulta?filter=${filtro}`).then(res => {
      if (res.data.length !== 0) {
        // Si la respuesta no esta vacia, setear los valores
        setListaArticulo(res.data);
      } else {
        // Si la respuesta esta vacia, realizar el segundo llamado a la API buscando las sugerencias
        apiFc.get(`/web/articulos/consulta/sugeridos?filter=${filtro}`)
          .then(res => { //Con esta funcion then espero la respuesta de la api
            if (res.data.length !== 0) {
              // si la respuesta no esta vacia, setear los valores
              setListaArticulo(res.data)
            } else {
              //si incluso la sugeencia no tiene resutados entonces beriamos mostrar cualquier cosa, agregalo en clickup
            }
          }).catch(err => { //Si falla
            setAlertDialog({
              ...alertDialog,
              visible: true,
              cabecera: "Error buscando articulos",
              texto: "Ocurrió un error al buscar los artículos.",
              severity: "warning"
            });
          });
      }
    }).catch(err => {
      setAlertDialog({
        ...alertDialog,
        visible: true,
        cabecera: "Error buscando articulos",
        texto: "Ocurrió un error al buscar los artículos.",
        severity: "warning"
      });
    });
  }

  //funcion para manejar el buscador delinput
  const handleInput = (event: any) => {
    if (event.code === "Enter") {
      buscarArticulos(event.target.value);
      event.target.blur();
    }
  };

  const containerRef = useRef<HTMLInputElement | null>(null);
  const handleClick = (event: any) => {
    buscarArticulos(containerRef?.current?.value || "");
    containerRef?.current?.blur();
  };

  useEffect(() => {
  }, [listaArticulo]);

  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  useEffect(() => {
  }, [alertDialog]);

  //Funcion para mostrar al enviar pedido esto deberia ser una funcion general
  function Mensaje() {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }

  function renderCuerpoTabla(): any {
    if (listaArticulo && listaArticulo?.length != 0) {
      var acumulado = 0;
      return (
        listaArticulo.map((item: any,) => (

          <tr key={item.articuloCodigo}>
            <td className="d-flex justify-content-center">
              <img className="pic-2" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${item.articuloImagenGXI}`} alt="product"
                onError={(e: any) => {
                  e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                }}
                style={{
                  height: '36px',
                  marginRight: '10px',
                  width: '36px',
                }}
              />

            </td>
            <th scope="row">{item.articuloCodigo}</th>
            <td>{item.articuloDescripcion}</td>
            <td>
              {item.articuloUnidadVenta}{" "}
              {item.articuloCantidadMultiplo != 0 &&
                <Button
                  variant=""
                  className="btn btn-warning paso4 "
                  type="button"
                  onClick={(e) => { CartelInformativo("El producto posee parametros de pedido!", `Minimo permitido: ${item.articuloCantidadMinSugerida} <br> Maximo Recomendado: ${item.articuloCantidadMaxSugerida} <br> Multiplo: ${item.articuloCantidadMultiplo}`) }}
                >
                  <i className="fa fa-info"></i>
                </Button>}

            </td>
            {(user.clienteConfiguracionMostrarCos) ?
              <td className="paso5">{formatearPrecio({ valor: parseFloat(item.articuloPrecio) })}</td> : ""}
            <td className="paso6"> {formatearPrecio({ valor: parseFloat((user.clienteConfiguracionMostrarIVA) ? item.articuloPrecioVentaSug1 * (1 + (item.articuloTasaIVA / 100)) : item.articuloPrecioVentaSug1) })}</td>
            <td className="paso7">{formatearPrecio({ valor: parseFloat((user.clienteConfiguracionMostrarIVA) ? item.articuloPrecioVentaSug2 * (1 + (item.articuloTasaIVA / 100)) : item.articuloPrecioVentaSug2) })}</td>
            <td className="paso8">{<StockLevel stock={item.articuloStock} stockMin={item.articuloStockMin} stockMax={item.articuloStockMax}></StockLevel>}</td>
            <td className="paso9">
              <InputCarritoCompra
                articulo={item}
                origen={"PuestoDeVenta"}
                onEvent={(e: AlertDialogInterface) =>
                  setAlertDialog(e)
                }
              />
            </td>
            <td>
              <h5>
                <div className="badge bg-primary ht-20 wd-80 pd-l" onClick={() => handleShowDetalleArticulo(item.articuloCodigo)} >Detalle</div>
              </h5>
            </td>
          </tr>
        ))
      );
    }

    return (
      <div style={{ height: "500px", alignItems: "center", position: "relative" }}>
        <span style={{ position: "absolute", bottom: "50%", left: "50%", whiteSpace: "nowrap" }}>{`No hay resultados`}</span>
      </div>);
  }


  useEffect(() => {
  }, [showDetalleArticulo])

  function handleShowDetalleArticulo(articuloCodigo: string) {
    setShowDetalleArticulo({ ...showDetalleArticulo, visible: true, codArticulo: articuloCodigo, fullScreen: "true" })
  }

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    // if (index === 8)
    //     setTabKey('tRenglones')
    // if(index === 13 )
    //     setTabKey('tPendientes')
    // if (index === 16)
    //     setTabKey('tEnviar')
    if (index === 1)
      setTimeout(() => {
        buscarArticulos('pala')
      }, 4000);
  };



  return (
    <div>
      <Joyride
        steps={pasosPDV}
        callback={handleJoyrideCallback}
        continuous={true}
        run={showTutorial}
        locale={{ back: 'Atras', close: 'Cerrar', last: 'Ultimo', next: 'Siguente', open: 'Abrir', skip: 'Saltear' }}
        spotlightClicks={false} //te deja o no hacer click en los resaltado
        showProgress={true}
        showSkipButton={true}

      />
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Puesto de venta
          </span>
          {alertDialog.visible && <Mensaje ></Mensaje>}
          {showDetalleArticulo?.visible &&
            <DetalleArticulo
              visible={showDetalleArticulo.visible}
              codArticulo={showDetalleArticulo.codArticulo}
              onHandleShow={() => setShowDetalleArticulo({ ...showDetalleArticulo, visible: false })}
            />
          }
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item btn-ayuda" active aria-current="page" onClick={() => setShowTutorial(!showTutorial)}>
              Ayuda
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      <div className="panel panel-primary tabs-style-2">
        <div className=" tab-menu-heading">
          <div className="tabs-menu1">
            <Card>
              <Card.Header className=" pb-0 paso1">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mg-b-0">Buscar productos</h4>
                </div>
                <p className="tx-12 tx-gray-500 mb-2">
                  {`El buscador buscara los primeros 20 productos. Utilice el punto (.) para buscar por codigo`}
                  <br />
                  {`Informacion que se muestra = 
                          Mg1: ${(user.clienteConfiguracionMargen1)}% 
                          Mg2: ${(user.clienteConfiguracionMargen2)}% 
                          Precio ${(user.clienteConfiguracionMostrarIVA) ? "CON iva" : "sin iva"}  
                          ` }
                </p>
                <p className="tx-8 tx-gray-500 mb-2"></p>
                <div className="input">
                  <BarraBusquedaSugerida
                    classNameContainer="input wd-100p"
                    classNameInput="form-control "
                    onSearch={(busqueda: respuestaBusqueda) => {
                      if (busqueda.type === 1) {
                        buscarArticulos(busqueda.value);
                      } else if (busqueda.type === 2) {
                        setIsLoadingArticulosGrupoSeleccionado(true)
                        setArticulosGrupoSeleccionado(busqueda.value)
                      }
                    }} />
                </div>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table className="table mg-b-0 text-md-wrap paso3">
                    <thead>
                      <tr>
                        <th>Imagen</th>
                        <th>Codigo</th>
                        <th>Descripcion</th>
                        <th>U.venta</th>
                        {(user.clienteConfiguracionMostrarCos) ? <th>Costo</th> : ""}
                        <th>Sug 1</th>
                        <th>Sug 2</th>
                        <th>Stock</th>
                        <th>Pedir</th>
                        <th>Mas detalle</th>
                      </tr>
                    </thead>
                    <tbody style={{ overflowY: "auto" }}>
                      {renderCuerpoTabla()}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PuestoDeVenta;


