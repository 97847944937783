
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Carousel, Col, Modal, Row, Table } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { setCondicionVenta, setPedidoActual, setPedidoActualRenglones, setPedidosDespachados, setPedidosSeguimientoCancelado, setPendientes, setTransportesCliente } from "../../../redux/actions/pedido";
import { setNovedades } from "../../../redux/actions/principal";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { isUserLogued, tamañoImagenNuevo } from '../../../functions/Procedimientos';
import { AlertDialogInterface } from '../../common/Interfaces';
import APIFc from '../../../services/APIFc';
import { setUserInfo } from '../../../redux/actions/auth';
import { consultaArticuloHeader } from '../../../redux/actions/grid';
import Slider from 'react-slick';
import "../../Card Inicio/cardarticulo.css"
import apiFc from '../../../services/APIFc';
import { decryptData } from '../../../functions/cryptoUtils';
import { Box } from '@mui/material';
import SliderMui, { SliderThumb } from "@mui/material/Slider";
import NuevosProductosInicio from '../../common/NuevosProductosInicio';
import ProductosRecomendadosInicio from '../../common/ProductosRecomendadosInicio';
import ProductosMasCompradosInicio from '../../common/ProductosMasCompradosInicio';
import ProductosDestacadosInicio from '../../common/ProductosDestacadosInicio';
import ShowMarcasInicio from '../../common/ShowMarcasInicio';
import ProductosDestacadosRubro from '../../common/ProductosDestacadosRubro';
import ShowLineasInicio from '../../common/ShowLineasInicio';
import ShowRubroInicio from '../../common/ShowRubroInicio';
import ModalPedidoInicio from '../../common/ModalPedidoInicio';
import ExtraLargeModal from '../../common/ExtraLargeModal';
import NotificationComponent from '../../NotificationComponent/NotificationComponent';
// import MessageMqtt from '../../MessageMQTT/MessageMqtt';

const marks = [
  {
    value: 0,
    label: "0",
  },

  {
    value: 100,
    label: "100",
  },
];
const Inicio = () => {
  const queries = {
    xs: '(max-width: 320px)', //query for xs devices
    sm: '(max-width: 720px)',
    md: '(max-width: 1024px)',
    xl: '(max-width: 1200px)',
    xxl: '(max-width: 2500px)'
  }
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 1000,
    arrows: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  const steps = [
    {
      target: ".empezamosPorAca",
      content: "Welcome!! Please spare a minute to learn about our page",
      disableBeacon: true
    },
    {
      target: ".seguimoaca",
      content: "You can log in here",
    },
    {
      target: ".signup",
      content: "Sign up here, if you're new",
    },
    {
      target: ".packages h3",
      content: "The packages we offer",
    },
    {
      target: ".explore",
      content: "Click here to find out more about other packages",
    },
    {
      target: ".footer .form",
      content: "Subscribe to our newsletter here",
    },
  ];

  const user = useSelector((store: any) => decryptData(store.authreducer.user));
  const pedidosDespachados = useSelector((store: any) => decryptData(store.pedidoreducer.pedidosDespachados));
  const pedidosSeguimientoCancelado = useSelector((store: any) => decryptData(store.pedidoreducer.pedidosSeguimientoCancelado));
  var fetchingData: boolean = false;
  const dispatch = useDispatch();
  const [showDetalle, setShowDetalle] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [articulosGrupo, setArticulosGrupo] = useState([]);
  const [items, setItems] = useState([]);
  const [isModalEmpty, setIsModalEmpty] = useState(false);
  const [pedidos, setPedidos] = useState<any[]>([]);
  const transportesDisponibles = useSelector((store: any) => decryptData(store.pedidoreducer.transportesDisponibles));
  const [alturaImagen, setAlturaImagen] = useState(window.innerHeight);


  useEffect(() => {
    if (isUserLogued()) {
      setBasic(true);
    }
    setAlertDialog({ ...alertDialog, visible: false })
    window.scrollTo(0, 0)
    let date = new Date()
    date.setDate(date.getDate() - 30);
    const handleResize = () => {
      setAlturaImagen(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [showMessage, setShowMessage] = useState(true);
  const handleRemoveMessage = () => {
    setShowMessage(false);
  };

  useEffect(() => {
    if (!fetchingData) {
      fetchingData = true;
      obtenerInformacionNecesaria();
    }
  }, [fetchingData]);

  const [queryParameters] = useSearchParams();
  const paramRubroId = queryParameters.get("rubroId")
  const [showModal, setShowModal] = useState(false);
  const [basic, setBasic] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const ejecutado = useRef(false);
  const shouldSetStateToLoading = false && user //&& novedades 
  useEffect(() => {
    if (!ejecutado.current) {
      ejecutado.current = true;

    }
  }, [shouldSetStateToLoading]);

  function obtenerInformacionNecesaria() {

    if (window.location.href.includes("/principal")) {
      APIFc.get(`web/cliente`)
        .then(res => {
          dispatch(setUserInfo(res.data))
        }).catch(err => {

        });
      APIFc.get(`/web/pedido/actual`)
        .then(res => {
          dispatch(setPedidoActual(res.data))
          APIFc.get(`/web/pedido/actual/renglon`)
            .then(res => {
              dispatch(setPedidoActualRenglones(res.data))
            }).catch(err => {
              dispatch(setPedidoActualRenglones(null))
            });
        }).catch(err => {
          dispatch(setPedidoActual(null))
        });
      APIFc.get(`/web/pedido/pendientes`)
        .then(res => {
          dispatch(setPendientes(res.data))
        }).catch(err => {
          dispatch(setPendientes([]))
        });
      APIFc.get(`/web/pedido/pedidos/despachados`)
        .then(res => {
          dispatch(setPedidosDespachados(res.data))
        }).catch(err => {
          dispatch(setPedidosDespachados([]))
        });
      APIFc.get(`/web/cliente/transportes`)
        .then(res => {
          dispatch(setTransportesCliente(res.data))
        }).catch(err => {
          dispatch(setTransportesCliente(null))
        });
      APIFc.get(`/web/cliente/condicionventa`)
        .then(res => {
          dispatch(setCondicionVenta(res.data))
        }).catch(err => {
          dispatch(setCondicionVenta(null))
        });
      APIFc.get(`/web/articulo/oferta`).then(res => {
        setOfertas(res.data);
      }).catch(err => {
        setOfertas([]);
        setAlertDialog({ ...alertDialog, visible: true, cabecera: "Problemas cargando ofertas", texto: `Error 405`, severity: "success" })
      });
    }

    APIFc.get(`/web/principal/novedades/last`)
      .then(res => {
        dispatch(setNovedades(res.data))
      }).catch(err => {
        dispatch(setNovedades(null))
      });

  }

  const [puntosRecorrido, setPuntosRecorrido] = useState<{ [key: string]: number }>({});
  const actualizarPuntosRecorrido = (pedidoNroMovVta: string, nuevoPunto: number) => {
    setPuntosRecorrido(prevPuntos => ({ ...prevPuntos, [pedidoNroMovVta]: nuevoPunto }));
  };

  const [sliderValue, setSliderValue] = useState(0);
  const fetchData = () => {

    if (paramRubroId !== null) {
      apiFc.get(`/web/articulos/rubros/${paramRubroId}/articulogrupos?page=0&rows=20`)
        .then(res => {
          setItems(res.data.content);
        })
        .catch(error => {
          console.error("Error al cargar los rubros", error);
        })
    }
  };

  useEffect(() => {
    fetchData();

  }, [paramRubroId])


  function handleRecibirPedido(pedidoNroMovVta: any): void {
    apiFc.post(`/web/pedido/pedidos/recibir?nmv=${pedidoNroMovVta}`, `{}`)
      .then(res => {
        apiFc.get(`/web/pedido/pedidos/despachados`)
          .then(res => {
            dispatch(setPedidosDespachados(res.data))
          }).catch(err => {
            dispatch(setPedidosDespachados([]))
          })
      }).catch(res => {

      });
  }

  // function obtenerPedidos(): void {
  //   apiFc.get(`/web/pedido/pedidos/historico?page=0&rows=10`).then(res => {
  //     setPedidos(res.data.content);

  //   }).catch(error => {
  //     console.log("error")
  //   });
  // }


  const handleDejarDeSeguir = (pedidoNroMovVta: string) => {
    const pedidosActuales = [...pedidosSeguimientoCancelado];
    if (!pedidosActuales.includes(pedidoNroMovVta)) {
      pedidosActuales.push(pedidoNroMovVta);
      dispatch(setPedidosSeguimientoCancelado(pedidosActuales));

    } else {
      console.log("error");
    }

    // setPedidoAEliminar(pedidoNroMovVta);
    const nuevosPedidosDespachados = pedidosDespachados.filter((pedido: any) => pedido.pedidoNroMovVta !== pedidoNroMovVta);
    dispatch(setPedidosDespachados(nuevosPedidosDespachados));

  };



  function imagenEstadoPedido(idEstado: number): string {

    switch (idEstado) {
      case 1:
        return ("ENVIADO.png");
      case 2:
        return ("RECIBIDO.png");
      case 3:
        return ("DEMORADO.png");
      case 4:
        return ("PROCESANDO.png");
      case 5:
        return ("EMBALADO.png");
      case 6:
        return ("FACTURADO.png");
      case 10:
        return ("DESPACHADO.png");
      case 20 || 21 || 22 || -70 || -101 || -102:
        return ("CANCELADO.png");
      case 50:
        return ("ENTREGADO.png"); //<a href="https://www.flaticon.es/iconos-gratis/entrega" title="entrega iconos">Entrega iconos creados por Freepik - Flaticon</a>

      default: return ("PROCESANDO.png");
    }
  }

  const codigoCliente = '';
  const handleClose = () => {
    setShowDetalle(false);
    setSelectedGroup({});
  }

  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  //Funcion para mostrar al enviar pedido esto deberia ser una funcion general
  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }

  const openModalSlider = (event: any) => {
    dispatch(consultaArticuloHeader(event))
  };
  const [data, setData] = useState<any[]>([]);
  const [ofertas, setOfertas] = useState([]);
  const [productoOferta, setProductoOferta] = useState([]);
  const navigate = useNavigate();
  const obtenerArticulosOferta = (item: any) => {
    navigate(`${process.env.PUBLIC_URL}/principal/articulos/ofertas/${item.id}`);
  };
  const { pedidoActualRenglones } = useSelector((store: any) => store.pedidoreducer);
  //si alguien disparo un cambio en la busqueda entonces refresco la pantalla,m pero antes asigno a Data el valor obtenido
  useEffect(() => {
  }, [pedidoActualRenglones]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const itemsToShow = windowWidth < 968 ? 8 : 6;
  const item = { variant: "primary", text: "Mensaje de alerta" };
  const randomRubroIndex = 1// Math.floor(Math.random() * (rubros?.length));
  const [randomRubros, setRandomRubros] = useState<any[]>([]);
  const [randomMarcas, setRandomMarcas] = useState<any[]>([]);


  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        {/* <>{extraLargeModal()}</> */}
        <ExtraLargeModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedGroup={selectedGroup}
          alertDialog={alertDialog}
          // setAlertDialog={setAlertDialog}
          windowWidth={windowWidth}
        />
        <ModalPedidoInicio
          basic={basic}
          setBasic={setBasic}
          transportesDisponibles={transportesDisponibles}
          puntosRecorrido={puntosRecorrido}
          actualizarPuntosRecorrido={actualizarPuntosRecorrido}
          setSliderValue={setSliderValue}
          imagenEstadoPedido={imagenEstadoPedido}
        />
        {alertDialog.visible && <Mensaje ></Mensaje>}
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <!-- row --> */}
      {/* {loading? (
        <Loader/>
      ):(<> */}
      <Row>
        <Col >
          <div className="justify-content-center empezamosPorAca">
            <div className="Withcontrols">
              <Carousel>
                <Carousel.Item>
                  <a
                    href="https://whatsapp.com/channel/0029VagMNruI1rchr3HAYa3z"
                    className="pd-l-40 pd-r-40"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="d-flex wd-100p justify-content-center align-items-center  seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel1${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        // e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel1_rot${tamañoImagen(window.innerWidth)}.png`
                      }}
                    />
                  </a>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center  seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel2${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        // e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel2_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        //e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
                <Carousel.Item>
                  <NavLink to={""}
                    className="pd-l-40 pd-r-40"
                  >
                    <img className="d-flex wd-100p justify-content-center align-items-center seguimoaca"
                      src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel4${tamañoImagenNuevo(window.innerWidth)}.jpg`}
                      alt=""
                      onError={(e: any) => {
                        //e.target.src = `../../../../img/Imagenes/Grupos/noimg.png`
                        //e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/inicio-carrusel/carrusel3_rot${tamañoImagen(window.innerWidth)}.png`
                      }
                      }
                    />
                  </NavLink>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Col >
      </Row >
      <div>
      {/* Llamar al componente de notificación */}
      
    </div>
      <NuevosProductosInicio />
      <ProductosRecomendadosInicio />
      <ShowLineasInicio id={123} />
      <ProductosDestacadosRubro />
      <ProductosMasCompradosInicio />
      <ShowLineasInicio id={456} />
      <ShowRubroInicio />
      <ProductosRecomendadosInicio />
      <ShowMarcasInicio />
      <ProductosDestacadosInicio />
      <ShowMarcasInicio />
      {(window.location.href.includes("/principal")) ?
        <Card className="custom-card mg-t-20">
          <Card.Body className=" ht-100p">
            <div>
              <h6 className="card-title mb-1">Haga click para conocer los productos de la oferta</h6>
            </div>
            {ofertas && ofertas?.length > 0 ?
              <div id="basicSlider">
                <div
                // className="MS-content"
                >
                  <Slider {...settings}>
                    {ofertas.map((oferta: any) => (
                      <div className="item" key={oferta.id}>
                        <img
                          src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${oferta.imagenUrl}`}
                          alt="product"
                          onError={(e: any) => {
                            e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                          }}
                          className="br-5"
                          onClick={() => obtenerArticulosOferta(oferta)}
                        />
                      </div>
                    )
                    )}
                  </Slider>
                </div>
              </div>
              : <></>
            }
          </Card.Body>
        </Card>
        : <></>

      }
      {/* </>)} */}
    </div >
  );
}

Inicio.propTypes = {};

Inicio.defaultProps = {};

export default Inicio;